//! Skrollaa parametrina annetun id:n kohdalle

function scrollToAnchor(linkId) {
    var aTag = $(linkId);
    $('html, body').animate({ scrollTop: aTag.offset().top }, 'slow');
}


function enable_nav_dropdowns($navigation, name) {
    $navigation.find('li.has-children').each(function() {
        $li = $(this);
        $index = $li.index();
        $li.find('a').first().
            attr({
                id: name + '_' + $index,
                'data-toggle': 'dropdown',
                'aria-haspopup': 'true',
                'aria-expanded': 'false'
            }).
            append(' <span class="caret"></span>');
        $li.find('ul').first().
            addClass('dropdown-menu').
            attr({
                'aria-labelledby': name + '_' + $index
            });
    })
    .promise()
    .done(function() {
        $navigation.find('.dropdown-toggle').dropdown('toggle');
    });
}

$(function() {
    $.each($('[data-navigation]'), function() {
        var $source = $(this).data("navigation");
        var $navigation = $(this);

        $("h1, h2, h3, h4", $source).each(function(i) {
            $(this).prop('id', 'toc' + i);
            $navigation.append('<li class="toc-' + this.tagName.toLowerCase() + '"><a href="#toc' + i + '">' + $(this).html() + '</a></li>');
        });
    });

    var pathname = window.location.href.split('#')[0];
    $('a[href^="#"]:not([href="#"]').each(function() {
        var $this = $(this),
            link = $this.attr('href');
        $this.attr('href', pathname + link);
    });

	// Skrollaus halutun elementin kohdalle (ks. funktio scrollToAnchor)
	$('[data-show-element]').each(function() {
		var $this = $(this);
		$this.on('click', function() {
			var show_element_id = '#' + $this.data('showElement');
			$(show_element_id).show();
			scrollToAnchor(show_element_id);
		});
	});

	$('a[data-scroll-to]').on('click', function(e) {
		e.preventDefault();
	    scrollToAnchor($(this).prop('hash'));
	});

	// Etusivun karuselli
	$('.main-carousel').slick({
		autoplay: true,
		autoplaySpeed: 5500,
        infinite: true
    });

    $('.js-stripe-banner').slick({
        autoplay: true,
        autoplaySpeed: 5500,
        infinite: true,
        fade: true,
        arrows: false,
        dots: false,
    });


    // PISTELIUKURIT
    // -------------

    ;(function($) {

        var calculatePointValues = function(slider, pointer_point_value) {
            var current_id = slider.attr("id");
            var output_points = $('output[for="'+current_id+'"][name="points"]');
            var output_eur = $('output[for="'+current_id+'"][name="eur"]');
            if (typeof pointer_point_value === 'undefined') {
                pointer_point_value = slider.val();
            }
            pointer_point_value = parseInt(pointer_point_value);

            // Kerätään arvot:
            var point_per_eur = parseInt(slider.data("point_per_eur"));       // yksi euro pisteissä
            var points_eur_value = pointer_point_value/point_per_eur;       // Käytettävien pisteiden euromääräinen arvo
            var total_eur_value = parseFloat(output_eur.data("value"));                 // tuotteiden hinta euroissa.
            var fixed_points = parseInt(slider.data("fixed_points")) || 0;         // Kiinteät pisteet, joita ei kompensoida
            var fixed_eur = parseFloat(slider.data("fixed_eur")) || 0;               // Kiinteä eurot, joita ei kompensoida
            var eur_left = Math.max(total_eur_value-points_eur_value+fixed_eur,0); // Maksettavaa euroissa (ottaen huomioon paljonko halutaan maksaa pisteillä)
            if (eur_left > 0) {
                eur_left = eur_left.toFixed(2)
            }

            // Asetetaan arvot
            output_points.html((pointer_point_value+fixed_points));
            output_eur.html(eur_left);
            return {
                "fixed_points": fixed_points,
                "eur_left": eur_left
            };
        }

        var togglePaymentMethods = function(enabled) {
            if (enabled) {
                $("#checkout_payment_method").show().find("input, select, textarea").prop("disabled", false);
            } else {
                $("#checkout_payment_method").hide().find("input, select, textarea").prop("disabled", true);
            }
        }

        // Ostoskorin pisteliukuri
        $('#form_order input[type=range]').rangeslider({
            polyfill: false,
            onSlide: function(position, pointer_point_value) {
                calculatePointValues(this.$element, pointer_point_value);
            },
            onSlideEnd: function(position, pointer_point_value) {
                var point_values = calculatePointValues(this.$element, pointer_point_value);
                togglePaymentMethods(point_values.eur_left > 0);
            },
            onInit: function() {
                var point_values = calculatePointValues(this.$element);
                togglePaymentMethods(point_values.eur_left > 0);
            }
        });

        // Tuotekortti
        $('#form_product input[type=range]').rangeslider({
            polyfill: false,
            onSlide: function(position, pointer_point_value) {
                calculatePointValues(this.$element, pointer_point_value);
            }
        });
    })(jQuery);

    // "Back to top" -linkki
    // ---------------------

    $(window).scroll(function() {
        $back_to_top_button = $('#back-to-top');

        if ($(this).scrollTop() > 200) {
            $back_to_top_button.fadeIn(200);
        } else {
            $back_to_top_button.fadeOut(200);
        }
    });
    
    $('#back-to-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate(
            {
                scrollTop: 0
            },
            300
        );
        window.location.hash="";
    });

        // Closes the sidebar menu
    $("#js-slidebar-close").click(function(e) {
        e.preventDefault();
        $("#js-slidebar").toggleClass("active");
    });
    
    // Opens the slidebar menu
    $("#js-slidebar-toggle").click(function(e) {
        e.preventDefault();
        $("#js-slidebar").toggleClass("active");
    });

    // Scrolls to the selected menu item on the page
    $('a[href*=#]:not([href=#],[data-toggle],[data-target],[data-slide])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                $('#js-slidebar-close').click();
            }
        }
    });

    // Merkitään navbarin aktiivinen linkki Bootstrapin omalla classilla
    $('.navbar-nav').find('.is-active').addClass('active');

    // Enabloidaan yläpalkin & navbarin mahdolliset dropdownit
    enable_nav_dropdowns($('.top-bar__link-area--general .top-bar-links'), 'top_bar_link');
    enable_nav_dropdowns($('.navbar-nav'), 'navbar_link');

    // "Check all" checkbox
    $("[data-check-all]").checkAllButton();
    $("[data-depends]").depends();
    $("form[data-ajax-submit]").ajaxSubmit();

    // Cookie consent
    if (!localStorage.getItem("cookieConsentGiven")) {
        $(".cookie-notification").show();
    }
    $("#js-cookie-consent-button").on("click", function(e) {
        e.preventDefault();
        localStorage.setItem("cookieConsentGiven", "1");
        $('.cookie-notification').remove();
    });

    // Yrityskentät pakollisiksi, kun valitaan ”Olen yritysasiakas”
    // Huom. inputin nimi perustuu data-toggler="checkbox" -skriptin ID:n perusteella luomaan nimeen
    $('input[name="toggle-js-company-fields"]').on('click', function() {
        $company_fields_container = $('#js-company-fields');
        $company_fields = $company_fields_container.find(':input');

        if ($(this).is(':checked')) {
            $company_fields.addClass('required');
        }
        else {
            clear_form_elements($company_fields_container);
            $company_fields.removeClass('required');
        }
    });

    $("[data-input='image-upload']").imageUpload();

    $('.flex-hero-carousel').slick({
        autoplay: true,
        autoplaySpeed: 5500,
        infinite: true
    });

    $('.js-hero-scroll-arrow').on('click', function(e) {
        e.preventDefault();
        var $next_block = $(this).closest('.home-top').next();
        $('html, body').animate({ scrollTop: $next_block.offset().top }, 'slow');
    });

    // Date(time)picker
    $('input[data-input="datepicker"]').datetimepicker({
        format: "DD.MM.YYYY",
        calendarWeeks: true,
        allowInputToggle: true,
        useCurrent: false,
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash'
        },
        locale: ($('html').attr('lang') == 'en' ? 'en-gb' : $('html').attr('lang'))
    });

});
