function clear_form_elements(element) {
	if (jQuery.type(element) === 'string') {
		element = $(element);
	}

	element.find(':input').each(function() {
		switch(this.type) {
			case 'password':
			case 'text':
			case 'email':
			case 'tel':
			case 'number':
			case 'textarea':
			case 'file':
			case 'select-one':
			case 'select-multiple':
			jQuery(this).val('');
			break;
			case 'checkbox':
			case 'radio':
			this.checked = false;
		}
	});
}

function get_stepped(element,method) {
	method = method || 'ceil';
	var current_value = parseInt(element.val());
	var step_attr = element.attr('step');
	var min_attr = element.attr('min');

	if (typeof min_attr !== typeof undefined && min_attr !== false) {
	    min = parseInt(min_attr);
	} else {
		min = 1;
	}

	// For some browsers, 'attr' is undefined; for others, 'attr' is false.  Check for both.
	if (typeof step_attr !== typeof undefined && step_attr !== false) {
	    step = parseInt(step_attr);
	} else {
		step = 1;
	}

	if (current_value < min) {
		current_value = min;
	}

	if (method == 'floor') {
		return Math.floor(current_value/step)*step;
	} else {
		return Math.ceil(current_value/step)*step;
	}
	
}


$(function() {

	//! Toggler
	$('[data-toggler="checkbox"]').each(function() {
		var $this = $(this);
		if ($this.data('checked') === 'checked') {
			$this.before('<div class="checkbox"><label><input type="checkbox" name="toggle-'+$this.attr("id")+'" checked="checked" data-toggle="next"> '+$this.data('toggler-title')+'</label></div>');
		}
		else {
			$this.before('<div class="checkbox"><label><input type="checkbox" name="toggle-'+$this.attr("id")+'" data-toggle="next"> '+$this.data('toggler-title')+'</label></div>').hide();
		}
	});
	
	$('[data-toggle="next"]').click(function() {
		// Mikäli checkbox on checked
		if ($(this).is(":checked")) {
			$(this).parent().parent().next().show("fast");
		}
		else {
		// piilotetaan
			var $elem = $(this).parent().parent().next().hide("fast");			
			clear_form_elements($elem);
		}
	});
	

	//! Qty +- signs
	$('input[data-input="qty-buttons"]')
		.css("text-align", "center")
		.wrap('<div class="input-group plain qty-buttons">')
		.parent()
		.prepend('<span class="input-group-btn"><button class="btn btn-default btn-sm" data-action="minus" type="button">&minus;</button></span>')
		.append('<span class="input-group-btn"><button class="btn btn-default btn-sm" data-action="plus" type="button">+</button></span>');

	$('button[data-action="minus"]').click(function() {
		var $qty_field = $(this).closest(".input-group").find('input[data-input="qty-buttons"]');
		var current_value = parseInt($qty_field.val());
		if (current_value > 1) {
			$qty_field.val(current_value-1);
			$qty_field.val(get_stepped($qty_field,'floor'));
			$qty_field.change();
		}
	});
	
	$('button[data-action="plus"]').click(function() {
		var $qty_field = $(this).closest(".input-group").find('input[data-input="qty-buttons"]');
		var current_value = parseInt($qty_field.val());
		if (isNaN(current_value)) {
			current_value = 0;
		}
		$qty_field.val(current_value+1);
		$qty_field.val(get_stepped($qty_field,'ceil'));
		$qty_field.change();
	});

	$('input[data-input="qty-buttons"]').change(function() {
		$(this).val(get_stepped($(this),'ceil'));
	});	

	$("form[data-required]").each(function() {
		var $current_form = $(this);
		var elements = $current_form.data("required").split(',');
		jQuery.each(elements, function(index, item) {
		    $current_form.find('input[name="'+item+'"]').attr('required',"required");
		});
	});

	//! Mark required fields
	$('input, textarea, select').not('[type="button"], [type="submit"], [type="checkbox"], [type="radio"]').filter('[required]').each(function() {
		var $this = $(this);
		var $form = $this.closest('form');
		var field_id = $this.prop('id');
		$form.find('label[for="' + field_id + '"]').append('<span class="required-marker"> *</span>');
	});

	$("[data-confirm-text]").on('click', function(e) {
		return confirm($(this).data('confirm-text'));
	});

	$('a[data-method="delete"]').on("click", function (e) {
		e.preventDefault();
		var url = $(this).attr("href");
		$.ajax({
	        url: url,
	        type: "DELETE",
		    complete: function(xhr, textStatus) {
		    	switch(xhr.status) {
				    case 200:
				        location.reload();
				        break;
				}
		    }
	    });
	});
	
	// 

});
