$(function() {

    // Mobiilikoossa tuotenimi siirretään kuvan yläpuolelle
    function change_product_heading_position() {
        if ($(window).width() < 768) {
            $('.product__name').prependTo('.product__image-wrapper');
        } else {
            $('.product__name').prependTo('.product__actions-wrapper');
        }
    }

    function disable_add_to_cart_button() {
        if ($('#sku_select') ) {
            if ($('#sku_select').val() === '') {
                $('#product_add_to_cart').attr('disabled', 'disabled');
            }
            else {
                $('#product_add_to_cart').removeAttr('disabled');
            }
        }
    }
    
    // Tuotehaitarin nuolet
    $('.product__panel .collapse').on('shown.bs.collapse', function() {
        $(this).parent().find(".fa-caret-right").removeClass("fa-caret-right").addClass("fa-caret-down");
    }).on('hidden.bs.collapse', function() {
        $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-right");
    });

    change_product_heading_position();

    // Ikkunan kokoa muutettaessa ajettavat skriptit
    $(window).resize(function() {
        change_product_heading_position();
    });

    $('#sku_select').on('change', function() {
        var optionSelected = $("option:selected", this);
        $(".product__price-value").html(optionSelected.data("price"));
        disable_add_to_cart_button();
    });

    disable_add_to_cart_button();

    $('.js-product-images-carousel').photoSwipe('a', {
        bgOpacity: 0.9,
        history: false,
        shareEl: false,
        fullscreenEl: false,
        zoomEl: false
    });

    $('.js-product-images-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.js-product-thumbnails-carousel',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true,
                    asNavFor: null,
                    dots: true,
                    fade: false
                }
            }
        ]
    });

    $('.js-product-thumbnails-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        asNavFor: '.js-product-images-carousel',
        centerMode: true,
        focusOnSelect: true,
        infinite: true
    });

    $('.js-product-thumbnail-link').on('click', function(e) {
        e.preventDefault();
    });

    $("#form_product").submit(function( event ) {
        event.preventDefault();

        var form_data = $(this).serialize();

        $.post("api/v1/cart/add", form_data, function(data) {
            // Show add to cart confirmation modal
            $('#addToCartConfirmationModal').modal('show');
        });
    });

    $('.product-page a[data-slide]').on('click', function(e) {
        e.preventDefault();
        var slideNo = $(this).data('slide');
        $('.product-page .js-product-images-carousel').slick('slickGoTo', slideNo - 1);
    });

    (function() {
        var $product_description = $('#js-product-description');
        var scrollHeight = $product_description.prop('scrollHeight');
        var maxHeight = $product_description.css('max-height');
        if ($product_description.prop('scrollHeight') > parseInt($product_description.css('max-height'))) {
            $('#js-product-read-more-container').removeClass('hidden');
        }
    })();

    $('#js-product-read-more-toggle').on('click', function(e) {
        e.preventDefault();
        var $product_description = $('#js-product-description');
        $product_description.css('max-height', 'none');
        $(this).parent().remove();
    });

    $('.js-product-carousel').photoSwipe('a', {
        bgOpacity: 0.9,
        history: false,
        shareEl: false,
        fullscreenEl: false,
        zoomEl: false
    });

    // New product page
    $('.js-product-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        // asNavFor: '.js-product-thumbnails-carousel',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true,
                    asNavFor: null,
                    dots: true,
                    fade: false
                }
            }
        ]
    });

    $('.product-page .js-product-thumbnail-link').on('click', function(e) {
        e.preventDefault();
    });
    
});
