(function($) {

  var defaultOptions = {
    accept:      '*',
    multiple:    false,
    inputName:   'file_upload',
    buttonLabel: '<i class="fa fa-fw fa-upload"></i> Upload',
    buttonClass: 'btn btn-sm btn-default btn-block',
  };

  function FileUpload(element, options) {
    this.element = $(element);
    if (this.element.data('options') !== undefined) {
      options = $.extend(true, {}, options, this.element.data('options'));
    }
    this.options = $.extend(true, {}, defaultOptions, options);
    // Make sure the input name is an array if multiple is true.
    if (this.options.multiple && !this.options.inputName.endsWith('[]')) {
      this.options.inputName += '[]';
    }
    this.items = this.element.data('items') || [];
    if (!$.isArray(this.items)) {
      this.items = [this.items];
    }
    // Filter out non-object items.
    this.items = this.items.filter(function(item) {
      return typeof item === 'object';
    });
    this.initialize();
  }

  /**
   * Initialize the plugin.
   */
  FileUpload.prototype.initialize = function initialize() {
    this.updateItems();
  };

  /**
   *
   */
  FileUpload.prototype.updateItems = function updateItems() {
    if (!this.itemsContainer) {
      this.itemsContainer = this.createElement('div', {
        class: 'file-upload__items-container',
      });
      this.element.append(this.itemsContainer);
    }
    var $items = this.items.map(function(item) {
      var name = item.id ? 'delete_' + this.options.inputName : undefined;
      var checkboxAttrs = {
        type:  'checkbox',
        name:  name,
        value: item.id,
        style: 'display:none;',
      };
      if (item.removed) {
        checkboxAttrs.checked = 'checked';
      }
      var $checkbox = this.createElement('input', checkboxAttrs);
      $checkbox.on('change', function($checkbox) {
        if ($checkbox.prop('checked')) {
          this.removeItem(item);
        } else {
          this.restoreItem(item);
        }
      }.bind(this, $checkbox));
      var $label = this.createElement('label', {
        style: 'cursor: pointer;',
      });
      var $action = this.createElement('i', {
        class: !item.removed
               ? 'fa fa-remove text-danger mr-2'
               : 'fa fa-undo text-warning mr-2',
      });
      var nameStyle = {'text-decoration': item.removed ? 'line-through' : 'none'};
      var iconClass = 'file-o';
      if (item.name.endsWith('.pdf')) {
        iconClass = 'file-pdf-o';
      } else if (item.name.match(/\.(png|jpe?g|gif|tiff)$/)) {
        iconClass = 'file-image-o';
      }
      var $icon = this.createElement('i', {
        class: 'm-x-1 fa fa-lg fa-' + iconClass,
      });
      var $name = item.url
                  ? this.createElement('a', {
            href:   item.url,
            target: '_blank',
          })
                  : this.createElement('span');
      $name.css(nameStyle).text(item.name).prepend($icon);
      var $wrapper = this.createElement('div');
      // Compose elements.
      $wrapper.append($label.prepend([$action, $checkbox]), $name);
      return $wrapper;
    }.bind(this));
    this.itemsContainer.empty().append($items);
    // Make sure we always have a file input visible if we need one.
    this.ensureFileInput();
  };

  /**
   *
   */
  FileUpload.prototype.ensureFileInput = function ensureFileInput() {
    if (!this.inputContainer) {
      this.inputContainer = this.createElement('div', {
        class: 'file-upload__input-container',
      });
      this.element.append(this.inputContainer);
    }
    var $currentInput = this.inputContainer.find('label:visible');
    if (!this.options.multiple && this.items.length) {
      // Multiple not allowed and we have some items, remove the input.
      $currentInput.remove();
      return;
    } else if ($currentInput.length) {
      // Skip creating if we already have a visible file input.
      return;
    }
    var inputOpts = {
      type:   'file',
      name:   this.options.inputName,
      style:  'display:none;',
      accept: this.options.accept,
    };
    if (this.options.multiple) {
      inputOpts.multiple = 'multiple';
    }
    var $input = this.createElement('input', inputOpts);
    var $button = this.createElement('label', {
      class: this.options.buttonClass,
    });
    $button.html(this.options.buttonLabel);
    $button.append($input);
    $input.on('change', this.onInputChange.bind(this, $input));
    this.inputContainer.append($button);
  };

  FileUpload.prototype.onInputChange = function onInputChange($input) {
    var files = $input[0].files;
    if (files.length === 0) {
      // Dialog was cancelled, don't do anything
      return;
    }
    // Hide the input button.
    $input.parent().hide();
    this.addItemsFromFileList($input);
  };

  FileUpload.prototype.addItemsFromFileList = function addItemsFromFileList($input) {
    Array.prototype.forEach.call($input[0].files, function(file) {
      this.items.push({
        id:     0,
        name:   file.name,
        $input: $input,
      });
    }.bind(this));
    this.updateItems();
  };

  FileUpload.prototype.removeItem = function removeItem(toRemove) {
    if (toRemove.$input) {
      // We need to remove all items that share the input because of the property of multiple file inputs.
      this.items = this.items.filter(function(item) {
        return item.$input !== toRemove.$input;
      });
      // Delete the input.
      toRemove.$input.parent().remove();
    } else {
      var item = this.items.find(function(item) {
        return item === toRemove;
      });
      item.removed = true;
    }
    this.updateItems();
  };

  FileUpload.prototype.restoreItem = function restoreItem(toRestore) {
    var item = this.items.find(function(item) {
      return item === toRestore;
    });
    delete item.removed;
    this.updateItems();
  };

  /**
   * Utility function to create a DOM element.
   * Returns a jQuery object.
   *
   * @param  {String} type
   * @param  {Object} attrs
   * @return {jQuery}
   */
  FileUpload.prototype.createElement = function createElement(type, attrs) {
    var elem = $("<" + type + ">");
    if (typeof attrs === "object") {
      elem.attr(attrs);
    }
    return elem;
  };

  $.fn.fileUpload = function fileUpload(options) {
    if ($(this).data("fileUpload") === undefined) {
      this.each(function() {
        var instance = new FileUpload(this, options || {});
        $(this).data("fileUpload", instance);
      });
      if (this.length > 1) {
        return this;
      }
    }
    return $(this).data("fileUpload");
  };
})(window.jQuery);
